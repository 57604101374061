import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap"
import QueryFilterRow from "components/Custom/QueryFilterRow"
import StatTable from "components/Custom/StatTable"
import triggerNotification from "utils/notifications"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import { extractPeriodAndStat, isStatColumn } from "utils/strings"
import { Modal } from "react-bootstrap"
import React, { useContext, useEffect, useRef, useState, useCallback } from "react"
import Slider from "nouislider"
import { UserContext } from "components/Contexts/UserContext"
import { MarketMovementModalContext } from "components/Contexts/MarketMovementModalContext"
import { API_URL } from "app"
import AuthProvider from "components/Token/AuthProvider"

const DIRECTIONS = ["TOP", "BOTTOM"]
const DEFAULT_PERIOD = "yesterday"
const DEFAULT_STAT = "open_close_variance"
const DEFAULT_DIRECTION = "TOP"
const DEFAULT_CARD_NAME = "Untitled"

function DashboardBuilder({ modalOpen, setModalOpen, setDataRefresher }) {
    const [authFetch] = AuthProvider()
    const { notifyRef } = useContext(UserContext)
    const { modalData, setModalData, modalMode, setModalMode, tableIndex, setTableIndex } = useContext(MarketMovementModalContext)
    const [queryData, setQueryData] = useState([])
    const [previewData, setPreviewData] = useState({})
    const [cardName, setCardName] = useState(DEFAULT_CARD_NAME)
    const [periods, setPeriods] = useState([])
    const [operators, setOperators] = useState([])
    const [stats, setStats] = useState([])
    const [filterOptions, setFilterOptions] = useState([])
    const [countSliderValue, setCountSliderValue] = useState(5)
    const [sortingStat, setSortingStat] = useState(DEFAULT_STAT)
    const [sortingPeriod, setSortingPeriod] = useState(DEFAULT_PERIOD)
    const [resultsDirection, setResultsDirection] = useState(DEFAULT_DIRECTION)
    const [sliderCreated, setSliderCreated] = useState(false)
    const sliderRef = useRef(null)

    const getStatsAndPeriods = () => {
        let url = `${API_URL}/get-periods-and-stats`
        authFetch(url, null, "COULD NOT GET STATS AND PERIODS").then((data) => {
            if (data) {
                setStats(data.stats)
                setPeriods(data.periods)
            } else {
                setStats([])
                setPeriods([])
            }
        })
    }

    const getQueryOptions = () => {
        let url = `${API_URL}/get-query-options`
        authFetch(url, null, "COULD NOT GET QUERY OPTIONS").then((data) => {
            if (data) {
                setFilterOptions(data.query_options)
                setOperators(data.operators)
            }
        })
    }

    useEffect(() => {
        getStatsAndPeriods()
        getQueryOptions()
        // eslint-disable-next-line
    }, [])

    const generateQueryData = useCallback((conditions) => {
        console.log("conditions")
        console.log(conditions)
        let query_data = []
        conditions.forEach((row, index) => {
            if (isStatColumn(row.col_name)) {
                const [period, stat] = extractPeriodAndStat(row.col_name)
                row.period = period
                row.col_name = stat
            } else if (row.col_name === "market_cap_usd") row.value /= Math.pow(10, 9)
            query_data.push(row)
        })
        return query_data
    }, [])

    useEffect(() => {
        console.log("modalData", modalData)
        setQueryData(generateQueryData(modalData?.conditions || []))
        setCardName(modalData?.name || DEFAULT_CARD_NAME)
        const [period, stat] = modalData?.sorting_col ? extractPeriodAndStat(modalData.sorting_col) : [DEFAULT_PERIOD, DEFAULT_STAT]
        setSortingPeriod(period)
        setSortingStat(stat)
        setResultsDirection(modalData?.direction || DEFAULT_DIRECTION)
        setCountSliderValue(modalData?.count || 5)
    }, [modalData, generateQueryData])

    useEffect(() => {
        if (queryData.length === 0) setSliderCreated(false)
        else if (queryData.length > 0 && !sliderCreated) {
            Slider.create(sliderRef.current, {
                start: [countSliderValue],
                connect: [true, false],
                step: 1,
                range: { min: 1, max: 20 },
            }).on("update", (values) => {
                setCountSliderValue(parseInt(values[0]))
            })
            setSliderCreated(true)
        }
    }, [queryData, countSliderValue, sliderCreated])

    const resetValues = () => {
        setQueryData([])
        setModalData({})
        setModalMode("new")
        setTableIndex(null)
        setPreviewData({})
        setCardName(DEFAULT_CARD_NAME)
        setCountSliderValue(5)
        setResultsDirection(DEFAULT_DIRECTION)
        setSortingPeriod(DEFAULT_PERIOD)
        setSortingStat(DEFAULT_STAT)
    }

    const discardChanges = () => {
        setModalOpen(false)
        resetValues()
    }

    const checkIncompleteRow = (row) =>
        row.col_name === "none" || row.period === "none" || row.operator === "none" || ["none", ""].includes(row.value)

    const checkIncompleteModalData = () => queryData.length > 0 && checkIncompleteRow(queryData[queryData.length - 1])

    const addRow = () => {
        setQueryData([...queryData, { col_name: "none", operator: "none", value: "none" }])
    }

    const updateRow = (index, value) => {
        if (filterOptions[value.col_name]["type"] === "stat" && !("period" in value)) value.period = "none"
        else if (filterOptions[value.col_name]["type"] !== "stat") delete value.period
        setQueryData([...queryData.slice(0, index), value, ...queryData.slice(index + 1)])
    }

    const removeRow = (index) => {
        setQueryData([...queryData.slice(0, index), ...queryData.slice(index + 1)])
    }

    const createPayload = () => {
        let payload = {
            name: cardName,
            count: countSliderValue,
            direction: resultsDirection,
            table_index: tableIndex,
            sorting_col: `${sortingPeriod}_${sortingStat}`,
        }
        payload["conditions"] = queryData.map((row, index) => ({
            col_name: filterOptions[row.col_name]["type"] === "stat" ? `${row.period}_${row.col_name}` : row.col_name,
            operator: row.operator,
            value: String(row.col_name === "market_cap_usd" ? row.value * Math.pow(10, 9) : row.value),
        }))

        return payload
    }

    // Make an API call then render, will have to create a separate rendering function
    const previewQuery = () => {
        setPreviewData(null)
        let body = createPayload()
        let url = `${API_URL}/preview`
        let payload = {
            method: "POST",
            body: JSON.stringify(body),
        }
        authFetch(url, payload, null).then((data) => {
            if (data) setPreviewData(data)
            else setPreviewData({})
        })
    }

    const saveCard = () => {
        let body = createPayload()
        let url = `${API_URL}/save-card`
        let payload = {
            method: "POST",
            body: JSON.stringify(body),
        }
        authFetch(url, payload, null).then((data) => {
            if (data) {
                setModalOpen(false)
                resetValues()
                triggerNotification(notifyRef, "New Dashboard Card Saved", "success")
                setDataRefresher(new Date())
            } else triggerNotification(notifyRef, "Failed To Save New Dashboard Card", "danger")
        })
    }

    const updateCard = () => {
        let body = createPayload()
        let url = `${API_URL}/update-card`
        let payload = {
            method: "POST",
            body: JSON.stringify(body),
        }
        authFetch(url, payload, null).then((data) => {
            if (data) {
                setModalOpen(false)
                resetValues()
                triggerNotification(notifyRef, "Dashboard Card Updated", "success")
                setDataRefresher(new Date())
            } else triggerNotification(notifyRef, "Failed To Update Dashboard Card", "danger")
        })
    }

    return (
        <Modal className="modal-dialog-centered" fullscreen="md-down" show={modalOpen} centered size={"xl"} dialogClassName="modal-90w">
            {/* Modal Header (title + close button) */}
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">
                    Create a query for a new dashboard card
                </h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={discardChanges}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            {/* The Filters */}
            <div className="modal-body">
                <Row>
                    <Col xs={12} lg={6}>
                        <Form role="form">
                            {queryData.length > 0 ? (
                                <FormGroup>
                                    {/* Name label */}
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <h4>Name</h4>
                                        </Col>
                                    </Row>
                                    {/* Name field */}
                                    <Row>
                                        <Col xs={12} className="text-center mb-2">
                                            <Input
                                                value={cardName}
                                                placeholder="Card Name"
                                                type="text"
                                                onChange={(e) => {
                                                    setCardName(e.target.value)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <h4>Filters</h4>
                                        </Col>
                                    </Row>
                                    {queryData.map((rowData, key) => (
                                        <QueryFilterRow
                                            filterOptions={filterOptions}
                                            periods={periods}
                                            operators={operators}
                                            removeFunc={removeRow}
                                            updateFunc={updateRow}
                                            rowIndex={key}
                                            rowData={rowData}
                                            key={key}
                                        />
                                    ))}
                                    {/* Sorting Column Label */}
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <h4>Sorting Column</h4>
                                        </Col>
                                    </Row>
                                    {/* Sorting Column Header */}
                                    <Row>
                                        <Col xs={5} className="text-center">
                                            <h4>Stat</h4>
                                        </Col>
                                        <Col xs={5} className="text-center">
                                            <h4>Period</h4>
                                        </Col>
                                        <Col xs={2} className="text-center">
                                            <h4>Direction</h4>
                                        </Col>
                                    </Row>
                                    {/* Sorting Column filter row + the count slider */}
                                    <Row>
                                        {/* Stat Name */}
                                        <Col xs={5}>
                                            <Input
                                                id="statSelect"
                                                type="select"
                                                value={sortingStat}
                                                onChange={(e) => {
                                                    setSortingStat(e.target.value)
                                                }}
                                            >
                                                {stats.map((prop, key) => (
                                                    <option key={key} value={prop[0]}>
                                                        {prop[1]}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                        {/* Duration */}
                                        <Col xs={5}>
                                            <Input
                                                id="periodSelect"
                                                type="select"
                                                value={sortingPeriod}
                                                onChange={(e) => {
                                                    setSortingPeriod(e.target.value)
                                                }}
                                            >
                                                {periods.map((prop, key) => (
                                                    <option key={key} value={prop[0]}>
                                                        {prop[1]}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                        {/* Direction */}
                                        <Col xs={2}>
                                            <Input
                                                type="select"
                                                value={resultsDirection.toUpperCase()}
                                                onChange={(e) => {
                                                    setResultsDirection(e.target.value.toLowerCase())
                                                }}
                                            >
                                                {DIRECTIONS.map((prop, key) => (
                                                    <option key={key} value={prop}>
                                                        {prop}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                        {/* Count Slider */}
                                        <Col xs={12}>
                                            <div className="input-slider-container mt-2">
                                                <h4 className="text-center">Result Count</h4>
                                                <div className="input-slider" ref={sliderRef} />
                                                <Row className="mt-3">
                                                    <Col xs="6">
                                                        <span className="range-slider-value">{countSliderValue}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            ) : (
                                <h3 className="mt-3 text-center">
                                    Nothing added yet
                                    <br />
                                    Click on "Add Filter" below to add filters to your query
                                </h3>
                            )}
                            {/* Add Filter Button */}
                            <Button color="info" className="float-right" onClick={addRow} disabled={checkIncompleteModalData()}>
                                Add Filter
                            </Button>
                            {/* Clear Filters Button */}
                            <Button
                                color="default"
                                className="float-right mr-3"
                                onClick={() => {
                                    setQueryData([])
                                }}
                                disabled={queryData.length === 0}
                            >
                                Clear
                            </Button>
                        </Form>
                    </Col>
                    {/* Preview Section */}
                    <Col xs={12} lg={6} className="my-auto">
                        <div className="mt-3">
                            {previewData ? (
                                previewData?.table ? (
                                    <StatTable title={previewData.title} tableData={previewData.table} sortingCol={previewData.sorting_col} />
                                ) : (
                                    <h1 className="text-center">Click Preview To Show Result</h1>
                                )
                            ) : (
                                <LoadingData />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            {/* Create Card & Preview Buttons */}
            <div className="modal-footer">
                <Button
                    color="success"
                    type="button"
                    disabled={queryData.length === 0 || checkIncompleteModalData() || cardName.length < 1}
                    onClick={modalMode === "new" ? saveCard : updateCard}
                >
                    {modalMode === "new" ? "Create Card" : "Update Card"}
                </Button>
                <Button
                    color="primary"
                    className="mr-auto"
                    onClick={previewQuery}
                    disabled={queryData.length === 0 || checkIncompleteModalData() || cardName.length < 1}
                >
                    Preview
                </Button>
            </div>
        </Modal>
    )
}

export default DashboardBuilder
